import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import { Menu, MenuItem } from '@cof/graffiti-alley-spray-cans/molecules/Menu';

import './CardHighlights.scss';

const CardHighlights = injectIntl(({ className }) => (
  <section className={className}>
    <header>
      <h3>
        <FormattedMessage id="cards.highlights.title" />
      </h3>
    </header>
    <Menu id="card-highlights" orientation="vertical" iconsPosition="top" align="center">
      <MenuItem icon="oi-credit-card outline" url="/credit-cards/compare/?filter=all" className="all-cards-link">
        <FormattedMessage id="cards.highlights.see-all" />
      </MenuItem>
      <MenuItem icon="oi-gift outline" url="/credit-cards/compare/?filter=rewards" className="rewards-cards-link">
        <FormattedMessage id="cards.highlights.get-rewards" />
      </MenuItem>
      <MenuItem icon="oi-trophy outline" url="/credit-cards/compare/?filter=build" className="build-cards-link">
        <FormattedMessage id="cards.highlights.build-or-rebuild" />
      </MenuItem>
    </Menu>
  </section>
));

CardHighlights.propTypes = {
  className: PropTypes.string,
  intl: intlShape,
};

CardHighlights.defaultProps = {
  className: '',
};

CardHighlights.displayName = 'CardHighlights';

export default CardHighlights;
