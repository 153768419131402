import React from 'react';
import { FormattedMessage } from 'react-intl';
import { superScript } from '../../lib/utils';

const HomePageLegalModalContent = () => (
  <div id="home-page-legal-content" className="plain-text">
    <h2>
      <FormattedMessage id="modals.home-page-legal-modal.quickcheck-legal" values={{ star: superScript('star') }} />
    </h2>
    <ol type="a">
      <li>
        <FormattedMessage
          id="modals.home-page-legal-modal.quickcheck-legal-li-0"
          values={{ qk: <span className="nowrap">Vérif Éclair</span> }}
        />
      </li>
      <li>
        <FormattedMessage id="modals.home-page-legal-modal.quickcheck-legal-li-1" />
      </li>
      <li>
        <FormattedMessage id="modals.home-page-legal-modal.quickcheck-legal-li-2" />
      </li>
      <li>
        <FormattedMessage id="modals.home-page-legal-modal.quickcheck-legal-li-3" />
      </li>
      <li>
        <FormattedMessage id="modals.home-page-legal-modal.quickcheck-legal-li-4" />
      </li>
    </ol>
    <p>
      <FormattedMessage
        id="modals.home-page-legal-modal.disclaimer"
        values={{ rball: superScript('rball'), md: superScript('md') }}
      />
    </p>
  </div>
);

export default HomePageLegalModalContent;
